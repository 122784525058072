:root {
    --awsui-color-text-control-disabled: #000000;
    --awsui-color-text-input-disabled: #000000;
}

.awsui .awsui-input.awsui-input-readonly,
.awsui .awsui-textarea.awsui-textarea-readonly {
    background-color: var(--awsui-color-background-input-disabled);
}

.awsui hr {
    border: 1px solid var(--awsui-color-background-input-disabled);
    margin-bottom: 20px;
}
